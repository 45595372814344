//COLORS
$primary-color: #e0fbfc;
$secondary-color: #9db4c0;
$accent-color: #5c6b73;
$background-color:  #253237;
$gold-color: #ffd700;
$mobile-width-breakpoint: 480px;

html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:'Lato', sans-serif;;
  font-size: 100%;
  height: 100%;
}